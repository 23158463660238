define("js2-client/components/sc-light-table/cells/ranking", ["exports", "@smith-carson/ui/components/sc-light-table/cells/ranking"], function (_exports, _ranking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ranking.default;
    }
  });
});